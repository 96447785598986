
export default {
  name: 'MegasredaContentBroadcastFeedCardSlide',
  props: {
    product: {
      type: Object,
      default: null,
    },
    draft: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isPast() {
      return (
        new Date(this.product?.date?.startDate).getTime() < new Date().getTime()
      )
    },
  },
  methods: {
    inView({ isInView }) {
      if (isInView && !this.isSetShow && !this.draft) {
        this.setShow()
      }
    },
    setShow() {
      this.$axios.$post('/activity/show/advertisement', {
        username: this.product.author?.username,
        advertisementId: this.product._id,
      })
      this.isSetShow = true
    },
    updateBroadcast() {
      this.$emit('update')
    },
    removeBroadcast() {
      this.$emit('remove')
    },
  },
}
